const getFromElOrSel = (elOrSel, isMultiMode = false) => {
  if (!elOrSel) { return null; }
  const method = isMultiMode ? 'querySelectorAll' : 'querySelector';
  const result = typeof elOrSel === 'string' ? document[method](elOrSel) : elOrSel;
  return isMultiMode ? Array.from(result) : result;
};

const hasClass = (element, cls) => element.classList.contains(cls);

// add "cls" class if element doesn't have it, or force add / remove with isActive option
const toggleClass = (elOrSel, cls, isActive = null) => {
  const element = getFromElOrSel(elOrSel);
  if (element && cls) {
    const isAdd = (isActive === null && !hasClass(element, cls)) || isActive;
    element.classList[isAdd ? 'add' : 'remove'](cls);
  }
};

const submitForm = (id) => {
  const form = document.getElementById(id);

  if (form) {
    form.submit();
  }
};

export {
  hasClass,
  toggleClass,
  submitForm
}