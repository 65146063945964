var $ = jQuery.noConflict();

const mobileSocialShare = () => {
  console.log("I'm in mobileSocialShare");
  $(".main-article__share__icon-all")
    .off("click")
    .on("click", function (e) {
      e.preventDefault();
      $(this)
        .find(".main-article__share__icon-all__secondary")
        .toggleClass("active");
      showSocialShare();
    });
};

const showSocialShare = () => {
  $(".main-article__share__icon-share--mobile a.open--mobile").click(function (
    e
  ) {
    let link;
    e.preventDefault();
    link = $(this).attr("href");
    window.open(link, "mywindow", "menubar=0,resizable=1,width=600,height=400");
    e.stopPropagation();
  });
};

const desktopSocialShare = () => {
  $(".main-article__share__icon-share a.open").click(function (e) {
    let link;
    e.preventDefault();
    link = jQuery(this).attr("href");
    window.open(link, "mywindow", "menubar=0,resizable=1,width=600,height=400");
  });
};

export { mobileSocialShare, desktopSocialShare };
