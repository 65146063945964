var $ = jQuery.noConflict();

const sliderTopArticles = () => {
  const breakpoint = window.matchMedia("(min-width:768px)");
  // keep track of swiper instances to destroy later
  const topArticles = document.querySelector(".top-articles");
  const blockTitle = document.querySelectorAll(
    ".top-articles__left__block-title"
  );
  let swiperTopArticlesTop;
  let swiperTopArticlesBottom;

  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      if (swiperTopArticlesBottom !== undefined) {
        swiperTopArticlesTop.destroy(true, true);
        swiperTopArticlesBottom.destroy(true, true);
      }
      enableDesktopTab();
      // or/and do nothing
      return;
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      disableDesktopTab();
      enableMobileSwiper();
      return;
    }
  };

  const enableDesktopTab = function () {
    $("#tab-1, [for=tab-1]").addClass("active");

    if (blockTitle) {
      blockTitle.forEach((el) => {
        el.addEventListener("mouseenter", function (event) {
          $(".top-articles__left__block-title").removeClass("active");
          $(".top-articles__right__image").removeClass("active");
          $(el).addClass("active");
          $("[for=" + event.target.id + "]").addClass("active");
        });
      });
    }
  };
  const disableDesktopTab = function () {
    $("#tab-1, [for=tab-1]").removeClass("active");

    if (blockTitle) {
      blockTitle.forEach((el) => {
        $(".top-articles__left__block-title").removeClass("active");
        $(".top-articles__right__image").removeClass("active");
      });
    }
  };

  const enableMobileSwiper = function () {
    swiperTopArticlesBottom = new Swiper(".swiperTopArticlesBottom", {
      autoHeight: true,
      spaceBetween: 20,
      slidesPerView: "auto",
      navigation: {
        nextEl: ".top-articles__nav__swiper-button-next",
        prevEl: ".top-articles__nav__swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination--top-articles",
        clickable: true,
      },
    });

    swiperTopArticlesTop = new Swiper(".swiperTopArticlesTop", {
      slidesPerView: "1",
      spaceBetween: 0,
    });

    swiperTopArticlesTop.controller.control = swiperTopArticlesBottom;
    swiperTopArticlesBottom.controller.control = swiperTopArticlesTop;
  };

  // keep an eye on viewport size changes
  breakpoint.addEventListener("change", breakpointChecker);
  // kickstart
  breakpointChecker();
};

export default sliderTopArticles;
