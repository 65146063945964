var $ = jQuery.noConflict();

const burgerMenu = () => {
  $(".header__menu__left")
    .off("click")
    .on("click", function (e) {
      $("body").toggleClass("nav-open");
      $(".search-banner").removeClass("active");
    });
};

const menuOutsideClick = () => {
  $(document)
    .off("click")
    .on("click", function (e) {
      var menu = $(".menu-main, .header__menu");
      if (!$(e.target).closest(menu).length && $("body").hasClass("nav-open")) {
        $("body").removeClass("nav-open");
      }
    });
};

export { burgerMenu, menuOutsideClick };
