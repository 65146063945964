var $ = jQuery.noConflict();
const sliderRelatedArticles = () => {
    const breakpoint = window.matchMedia("(min-width:768px)");
    // keep track of swiper instances to destroy later
    let swiperRelatedArticles;

    const breakpointChecker = function () {
        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {
            // clean up old instances and inline styles when available
            /* if (swiperSocialFeed !== undefined) swiperSocialFeed.destroy(true, true); */
            // or/and do nothing
            return enableSwiper();
            // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {
            // fire small viewport version of swiper
            return enableSwiper();
        }
    };

    const enableSwiper = function () {
        swiperRelatedArticles = new Swiper(".sliderRelatedArticles", {
            loop: false,
            slidesPerView: "auto",
            spaceBetween: 15,
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        });
    };

    // keep an eye on viewport size changes
    breakpoint.addEventListener("change", breakpointChecker);
    // kickstart
    breakpointChecker();
};

export default sliderRelatedArticles;


// swiper - related articles in sidebar

const swiper = new Swiper('.sliderRelatedSidebar', {
    loop: false,
    slidesPerView: 1,
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

