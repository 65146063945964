import sliderTopArticles from "./sliderTopArticles";
import sliderFolder from "./sliderFolder";
import sliderVideo from "./sliderVideo";
import sliderSocialFeed from "./sliderSocialFeed";
import sliderArticlesProducts from "./sliderArticlesProducts";
import sliderRelatedArticles from "./sliderRelatedArticles";
import boxSelector from "./boxSelector"
import {searchIcon, searchOutsideClick} from "./handleSearch";
import {burgerMenu, menuOutsideClick} from "./handleMenu";
import {mobileSocialShare, desktopSocialShare} from "./handleSocialShare";

var $ = jQuery.noConflict();

const initJS = () => {
    let isMobile = window.matchMedia(
        "only screen and (max-width: 768px)"
    ).matches;

    //MOVE COOKIE BUTTON IN FOOTER LIST
    $("#secondary-menu").append($("#ot-sdk-btn"));

    // SLIDERS
    sliderTopArticles();
    sliderFolder();
    sliderVideo();
    sliderSocialFeed();
    sliderArticlesProducts();
    sliderRelatedArticles();
    boxSelector();

    // MENU CLICK
    burgerMenu();

    // CLICK OUTSIDE FOR MENU
    if (!isMobile) {
        menuOutsideClick();
    }

    //CLICK SEARCH ICON DESKTOP
    searchIcon();

    // CLICK OUTSIDE FOR SEARCH
    searchOutsideClick();

    // SOCIAL SHARE MOBILE
    mobileSocialShare();

    // SOCIAL SHARE
    desktopSocialShare();

    // PAGINATION CLICK
    $(".dropdown").on("click", function () {
        $(this).toggleClass("is-active");
    });

    // PAGINATION CLICK OUTSIDE
    $(document).on("click", function (e) {
        var dropdown = $(".dropdown");
        if (!$(e.target).closest(dropdown).length) {
            $(dropdown).removeClass("is-active");
        }
    });

    // DROPDOWN CHANGE LANGUAGE CLICK
    $(".dropdown-language").on("click", function () {
        $("body").toggleClass("dropdown-language-active");
    });

    // DROPDOWN CHANGE LANGUAGE
    $(document).on("click", function (e) {
        var menu = $(".dropdown-language-choice, .dropdown-language");
        if (!$(e.target).closest(menu).length && $("body").hasClass("dropdown-language-active")) {
            $("body").removeClass("dropdown-language-active");
        }
    });

    // STICKY MENU
    $(window).scroll(function () {
        const sticky = $(".sticky");
        let scroll = $(window).scrollTop();

        if (scroll >= 50) sticky.addClass("fixed");
        else sticky.removeClass("fixed");
    });

    // HIDE BUTTON SHOW MORE
    $(".showMore").on("click", function (e) {
        $(this).hide();
        $("#offset").show();
        $(".showAll").show();
    });

    // ACCORDION FOOTER
    if (isMobile) {
        const acc = document.getElementsByClassName("main-footer__nav__row__title");
        let i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                let panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                } else {
                    panel.style.display = "block";
                }
            });
        }
    }

    //COPY BUTTON
    $(".copyToClipboard").on("click", function () {
        var inputc = document.body.appendChild(document.createElement("input"));
        inputc.value = window.location.href;
        inputc.focus();
        inputc.select();
        document.execCommand("copy");
        inputc.parentNode.removeChild(inputc);
    });


    // Remove sticky sidebar if there is more than one widget
    jQuery(document).ready(function () {
        var sidebar_selector = jQuery(".main-article__sidebar__contain");
        var count = sidebar_selector.children().length;
        if (count > 1) {
            sidebar_selector.removeClass('sticky');
            sidebar_selector.css('position', 'initial');
            //sidebar_selector.children().eq(2).addClass('sticky');
        }
    });

    // const breakpoint = window.matchMedia("(min-width:768px)");

    // const breakpointChecker = function () {
    //   if (breakpoint.matches === true) {
    //     enableDesktop();
    //     return;
    //   } else if (breakpoint.matches === false) {
    //     enableMobile();
    //     return;
    //   }
    // };

    // const enableDesktop = function () {
    //   console.log("mobile");
    // }

    // const enableMobile = function () {
    //   console.log("mobile");
    // }

    // breakpoint.addEventListener("change", breakpointChecker);

    // breakpointChecker();
};

const refresh = () => {
    let isMobile = window.matchMedia(
        "only screen and (max-width: 768px)"
    ).matches;

    if (!isMobile) {
        menuOutsideClick();
        searchOutsideClick();
    }

    // SLIDER
    sliderRelatedArticles();

    // MENU CLICK
    burgerMenu();

    //CLICK SEARCH ICON DESKTOP
    searchIcon();

    // SOCIAL SHARE MOBILE
    mobileSocialShare();

    // SOCIAL SHARE
};

export default {initJS, refresh};

window.refresh = refresh;
initJS();
