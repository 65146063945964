var $ = jQuery.noConflict();

const searchIcon = () => {
  $(".header__menu__search-button")
    .off("click")
    .on("click", function (e) {
      $(".search-banner").toggleClass("active");
      $("body").removeClass("nav-open");
    });
};

const searchOutsideClick = () => {
  $(document).on("click", function (e) {
    var searchBanner = $(".search-banner, .header");
    if (
      !$(e.target).closest(searchBanner).length &&
      $(".search-banner").hasClass("active")
    ) {
      $(".search-banner").removeClass("active");
    }
  });
};

export { searchIcon, searchOutsideClick };
