var $ = jQuery.noConflict();

const sliderVideo = () => {
  const breakpoint = window.matchMedia("(min-width:768px)");
  // keep track of swiper instances to destroy later

  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      // if (swiperVideo !== undefined) swiperVideo.destroy(true, true);
      // or/and do nothing
      return enableSwiper('desktop');
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper('mobile');
    }
  };

  const enableSwiper = function (evt) {
    let currentVideo = 0;
    let swiperVideoRight = new Swiper(".chaine-video__right", {
      direction: evt == "mobile" ? "horizontal" : "vertical",
      spaceBetween: 10,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        click: function () {
          //STOP VIDEO IF CLICK OTHER VIDEO
          if (currentVideo != this.clickedSlide.id) {
            $(".yvideo").each(function () {
              this.contentWindow.postMessage(
                '{"event":"command","func":"stopVideo","args":""}',
                "*"
              );
            });
          }
          currentVideo = this.clickedSlide.id;
        },
      },
    });
    let swiperVideoLeft = new Swiper(".chaine-video__left", {
      direction: "horizontal",
      spaceBetween: 15,
      slidesPerView: "auto",
      thumbs: {
        swiper: swiperVideoRight,
      },
    });
  };

  // keep an eye on viewport size changes
  breakpoint.addEventListener("change", breakpointChecker);
  // kickstart
  breakpointChecker();
};

export default sliderVideo;