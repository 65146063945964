import { toggleClass, submitForm } from "./util";

const boxSelector = () => {
  const emptyErrorBoxVerticalSelect = document.querySelector(".js-errorBoxVertical--empty");
  const zeroErrorBoxVerticalSelect = document.querySelector(".js-errorBoxVertical--zero");

  const emptyErrorBoxHorizontalSelect = document.querySelector(".js-errorBoxHorizontal--empty");
  const zeroErrorBoxHorizontalSelect = document.querySelector(".js-errorBoxHorizontal--zero");

  const fieldContainers = document.querySelectorAll(".js-selector-container");
  
  const btnSubmitBoxVertical = document.getElementById("btnSubmitBoxVertical");
  const btnSubmitBoxHorizontal = document.getElementById("btnSubmitBoxHorizontal");


  const getTcValue = (cls) => document.querySelector(cls)?.value;

  const checkTcValue = (cls) => getTcValue(cls) === null || getTcValue(cls) === '';

  const toggleErrorForBoxOrBagElement = (type = '', value = true, error = null) => {
    console.log(type);
    console.log(error);
    switch (type) {
      case "formBoxVertical":
        switch (error) {
          case "empty":
            toggleClass(emptyErrorBoxVerticalSelect, "error", value);
            break;
          case "zero":
            toggleClass(zeroErrorBoxVerticalSelect, "error", value);
            break;
          default:
            break;
        }
        break;
      case "formBoxHorizontal":
        switch (error) {
          case "empty":
            toggleClass(emptyErrorBoxHorizontalSelect, "error", value);
            break;
          case "zero":
            toggleClass(zeroErrorBoxHorizontalSelect, "error", value);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const checkFormError = (form) => {
    let error = false;
    let zeroError = false;
    let emptyError = false;
    
    switch (form) {
      case "formBoxVertical":
        emptyError =
          checkTcValue(".js-boxl") ||
          checkTcValue(".js-boxL") ||
          checkTcValue(".js-boxH");
        zeroError =
          parseInt(getTcValue(".js-boxl")) <= 0 ||
          parseInt(getTcValue(".js-boxL")) <= 0 ||
          parseInt(getTcValue(".js-boxH")) <= 0;
        error = emptyError || zeroError;

        toggleErrorForBoxOrBagElement("formBoxVertical", emptyError, "empty");
        emptyError
          ? toggleErrorForBoxOrBagElement("formBoxVertical", false, "zero")
          : toggleErrorForBoxOrBagElement("formBoxVertical", zeroError, "zero");
        break;
      case "formBoxHorizontal":
        emptyError =
          checkTcValue(".js-boxHorizontall") ||
          checkTcValue(".js-boxHorizontalL") ||
          checkTcValue(".js-boxHorizontalH");
        zeroError =
          parseInt(getTcValue(".js-boxHorizontall")) <= 0 ||
          parseInt(getTcValue(".js-boxHorizontalL")) <= 0 ||
          parseInt(getTcValue(".js-boxHorizontalH")) <= 0;
        error = emptyError || zeroError;
console.log(emptyError);
console.log(zeroError);
        toggleErrorForBoxOrBagElement("formBoxHorizontal", emptyError, "empty");
        emptyError
          ? toggleErrorForBoxOrBagElement("formBoxHorizontal", false, "zero")
          : toggleErrorForBoxOrBagElement("formBoxHorizontal", zeroError, "zero");
        break;
      default:
        error = false;
        break;
    }
    checkFormInputs(form);
    return error;
  };

  const checkFormInputs = (formId) => {
    const form = document.getElementById(formId);
    if (!form) {
      return;
    }
    const containers = form.querySelectorAll(".js-selector-container");
    containers.forEach((container) => {
      const field = container.querySelector(".js-selector-field");
      if (!field) {
        return;
      }
      if (field.value == "" || parseInt(field.value) <= 0) {
        toggleClass(container, "error", true);
        toggleClass(container, "success", false);
      } else {
        toggleClass(container, "success", true);
        toggleClass(container, "error", false);
      }
    });
  };

  const formatFieldLabels = () => {
    fieldContainers.forEach((container) => {
      const containerField = container.querySelector(".js-selector-field");
      if (containerField) {
        containerField.addEventListener("focus", () => {
          toggleClass(container, "active", true);
        });
        containerField.addEventListener("blur", () => {
          toggleClass(container, "active", !(containerField.value == ""));
        });
      }
    });
  };

  const handleForm = (formId, submitBtnId) => {
    if (!formId || !submitBtnId) {
      return;
    }
    console.log(!checkFormError(formId));
    if (!checkFormError(formId)) {
      // submitForm(formId);
    }
  };

  if (btnSubmitBoxVertical != null) {
    btnSubmitBoxVertical.onclick = () => {
      handleForm("formBoxVertical", "btnSubmitBoxVertical");
    };
  }
  if (btnSubmitBoxHorizontal != null) {
    btnSubmitBoxHorizontal.onclick = () => {
      handleForm("formBoxHorizontal", "btnSubmitBoxHorizontal");
    };
  }

  formatFieldLabels();
};

export default boxSelector;