var $ = jQuery.noConflict();

const sliderArticlesProducts = () => {
  const breakpoint = window.matchMedia("(min-width:768px)");
  // keep track of swiper instances to destroy later
  let swiperArticlesSwiper;

  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      /* if (swiperSocialFeed !== undefined) swiperSocialFeed.destroy(true, true); */
      // or/and do nothing
      return enableSwiper();
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper();
    }
  };

  const enableSwiper = function () {
    swiperArticlesSwiper = new Swiper(".sliderArticlesProducts", {
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 15,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: ".top-articles__nav__swiper-button-next",
        prevEl: ".top-articles__nav__swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination--articles-products",
        clickable: true,
      },
    });
  };

  // keep an eye on viewport size changes
  breakpoint.addEventListener("change", breakpointChecker);
  // kickstart
  breakpointChecker();
};

export default sliderArticlesProducts;
