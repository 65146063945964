var $ = jQuery.noConflict();

const sliderFolder = () => {
  const breakpoint = window.matchMedia("(min-width:768px)");
  // keep track of swiper instances to destroy later
  let swiperFolders;
  
  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      if (swiperFolders !== undefined) swiperFolders.destroy(true, true);
      // or/and do nothing
      return;
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper();
    }
  };
  
  const enableSwiper = function () {
    swiperFolders = new Swiper(".sliderFolder", {
      direction: "horizontal",
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination--folder",
        clickable: true,
      },
    });
  };
  
  // keep an eye on viewport size changes
  breakpoint.addEventListener("change", breakpointChecker);
  // kickstart
  breakpointChecker();
};

export default sliderFolder;